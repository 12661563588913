import React from "react";

const PageHeader = ({ title }) => {
    return (
        <div className="relative w-full bg-gradient-to-r from-primary to-primaryLight text-white pt-32 pb-20 text-center">
            <div className="container mx-auto px-6">
                <h1 className="text-4xl md:text-5xl font-extrabold">{title}</h1>
                <div className="w-20 h-2 bg-white mx-auto mt-4"></div>
            </div>
        </div>
    );
};

export default PageHeader;
