import React from "react";
import { NavLink } from "react-router-dom";

const ServiceCards = () => {
    return (
        <div className="w-full bg-gradient-to-r from-primaryLight to-white">
            <div className="container mx-auto px-4 py-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">

                {/* Card 1 - Usluge */}
                <Card
                    image="/images/usluge.jpg"
                    title="Usluge"
                    description="Otkrijte široku ponudu naših frizerskih usluga."
                    link="/usluge"
                    object_type="cover"
                />

                {/* Card 2 - Proizvodi */}
                <Card
                    image="/images/proizvodi.jpg"
                    title="Proizvodi"
                    description="Kvalitetni proizvodi za njegu vaše kose."
                    link="/proizvodi"
                    object_type="cover"
                />

                {/* Card 3 - Kontakt */}
                <Card
                    image="/images/kontakt.png"
                    title="Kontakt"
                    description="Posjetite nas ili nas kontaktirajte za više informacija."
                    link="/kontakt"
                    object_type="contain"
                />
            </div>
        </div>
    );
};

const Card = ({ image, title, description, link, object_type }) => {
    return (
        <div className="group block bg-white rounded-lg shadow-lg overflow-hidden">
            <img src={image} alt={title} className={`w-full h-48 object-${object_type}`} />
            <div className="p-6">
                <h3 className="text-xl font-bold text-secondary">{title}</h3>
                <p className="text-gray-600 mt-2 text-left">{description}</p>
                <NavLink
                    to={link}
                    className="my-4 float-right inline-block bg-primary hover:bg-secondary text-white text-lg font-semibold px-4 py-2 rounded-lg shadow-md transition transform hover:bg-primary-dark"
                >
                    Saznaj više
                </NavLink>
            </div>
        </div>
    );
};

export default ServiceCards;
