import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import About from "./pages/About";
import NotFound from "./pages/NotFound";

function App() {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                {/* Navbar always at the top */}
                <Navbar />

                {/* Page Content */}
                <main className="flex-grow">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/o-nama" element={<About />} />
                        <Route path="/usluge" element={<Services />} />
                        <Route path="/kontakt" element={<Contact />} />
                        <Route path="/proizvodi" element={<Products />} />
                        {/* Define other routes here */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </main>

                {/* Footer always at the bottom */}
                <Footer />
            </div>
        </Router>
    );
}

export default App;