import React, { useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@radix-ui/react-accordion";
import PageHeader from "../components/PageHeader";
import GalleryMas from "../components/GalleryMas";
import useSEO from "../hooks/useSEO";

const ServicesPage = () => {
    useSEO(
        "Usluge | Frizerski salon EN - Rijeka",
        "Frizerski salon EN u Rijeci nudi vrhunske usluge šišanja, bojanja, keratinskog ravnanja, balayage pramenova i specijalizirane tretmane poput THERMOCUT šišanja. Posvetite pažnju zdravlju svoje kose uz inovativne tehnike i proizvode bez amonijaka.",
        "https://frizerski-salon-en.hr/usluge",
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "name": "Frizerski salon EN - Usluge",
            "url": "https://frizerski-salon-en.hr/usluge",
            "description": "U Frizerskom salonu EN pružamo vrhunske usluge šišanja, bojanja, keratinskog ravnanja, balayage pramenova, te specijalizirane tretmane poput THERMOCUT šišanja. Naša ponuda uključuje inovativne tehnike i proizvode bez amonijaka, za zdravlje i ljepotu vaše kose.",
            "serviceType": [
                "Šišanje",
                "Bojanje kose",
                "Balayage pramenovi",
                "THERMOCUT šišanje",
                "Keratinsko ravnanje kose"
            ],
            "provider": {
                "@type": "HairSalon",
                "name": "Frizerski salon EN",
                "url": "https://frizerski-salon-en.hr",
                "telephone": "+385915948284",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Tihovac 12",
                    "addressLocality": "Rijeka",
                    "addressCountry": "HR"
                },
                "sameAs": [
                    "https://www.facebook.com/FrizerskiSalonEN",
                    "https://www.instagram.com/FrizerskiSalonEN"
                ]
            }
        }
    );

    const [isMounted, setIsMounted] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [processedImages, setProcessedImages] = useState([]);

    // Fetch categories
    useEffect(() => {
        const fetchServices = async () => {
            try {
                const API_URL = process.env.REACT_APP_API_URL;

                const response = await fetch(`${API_URL}/categories`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }

                const data = await response.json();

                // Filter out categories with no services
                const filteredCategories = data.filter(category => category.services.length > 0);

                setCategories(filteredCategories);
                setLoading(false);
            } catch (err) {
                setError("Failed to load services.");
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    // Process images - SINGLE useEffect for image processing
    useEffect(() => {
        const importAll = (r) => r.keys().map(r);

        // Process images
        const processImages = async () => {
            const importedImages = importAll(require.context('/public/images/pages', false, /\.(jpg|jpeg|png)$/));

            // Process images with proper dimensions
            const processedImagesArray = await Promise.all(importedImages.map((src, index) => {
                return new Promise((resolve) => {
                    let fileExtension = src.split('.').pop();
                    let editedSrc = src.split('/').pop();
                    let hashIndex = editedSrc.indexOf('.');
                    let editedSrcWithoutHash = editedSrc.substring(0, hashIndex) + '.' + fileExtension;
                    const path = `/images/pages/${editedSrcWithoutHash}`;

                    // Only include services images
                    if (!path.includes('services')) {
                        resolve(null);
                        return;
                    }

                    const alt = `Frizerski salon En ${index + 1}`;
                    const img = new Image();

                    img.onload = () => {
                        resolve({
                            src: path,
                            alt,
                            width: img.naturalWidth,
                            height: img.naturalHeight
                        });
                    };

                    img.onerror = () => {
                        // Return fallback dimensions if image fails to load
                        resolve({
                            src: path,
                            alt,
                            width: 800,  // fallback width
                            height: 600  // fallback height
                        });
                    };

                    img.src = path;
                });
            }));

            // Filter out null values (non-service images)
            const filteredImages = processedImagesArray.filter(img => img !== null);
            setProcessedImages(filteredImages);
            setImagesLoaded(true);
        };

        processImages();
    }, []);

    // Only mount gallery when everything is ready
    useEffect(() => {
        if (!loading && imagesLoaded) {
            setTimeout(() => {
                setIsMounted(true);
                // Force recalculation after a moment
                setTimeout(() => {
                    window.dispatchEvent(new Event("resize"));
                }, 100);
            }, 500);
        }
    }, [loading, imagesLoaded]);

    // Render loading state
    if (loading) {
        return (
            <div className="flex flex-wrap items-center">
                <PageHeader title="Usluge" />

                <div className="container mx-auto px-6 py-12 flex flex-wrap content-center">
                    <div className="flex flex-wrap content-center mx-auto max-w-6xl">
                        <p className="text-lg text-gray-600">
                            U <strong>Frizerskom salonu „EN"</strong> posvećeni smo zdravlju i ljepoti vaše kose.
                            Koristimo vrhunske proizvode bez amonijaka, inovativne tehnike šišanja i bojanja te nudimo
                            specijalizirane tretmane poput <strong>THERMOCUT šišanja</strong>, <strong>balayage
                            pramenova</strong> i <strong>keratinskog ravnanja</strong>.
                        </p>
                        <p className="mt-2 text-lg text-gray-600">
                            Bilo da želite osvježiti frizuru, promijeniti boju ili pripremiti se za posebnu priliku – na
                            pravom ste mjestu!
                            Pogledajte našu ponudu i pronađite savršenu uslugu za sebe.
                        </p>

                        <div aria-label="Loading..." role="status" className="flex items-center space-x-2">
                            <svg className="h-10 w-10 animate-spin stroke-gray-500" viewBox="0 0 256 256">
                                <line x1="128" y1="32" x2="128" y2="64" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="24"></line>
                                <line x1="195.9" y1="60.1" x2="173.3" y2="82.7" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="24"></line>
                                <line x1="224" y1="128" x2="192" y2="128" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="24">
                                </line>
                                <line x1="195.9" y1="195.9" x2="173.3" y2="173.3" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="24"></line>
                                <line x1="128" y1="224" x2="128" y2="192" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="24">
                                </line>
                                <line x1="60.1" y1="195.9" x2="82.7" y2="173.3" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="24"></line>
                                <line x1="32" y1="128" x2="64" y2="128" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="24"></line>
                                <line x1="60.1" y1="60.1" x2="82.7" y2="82.7" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="24">
                                </line>
                            </svg>
                            <span className="text-2xl font-medium text-gray-500">Učitavanje...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // Render error state
    if (error) {
        return (
            <div className="flex flex-wrap items-center">
                <PageHeader title="Usluge" />
                <div className="container mx-auto px-6 py-12 flex flex-wrap content-center">
                    <div className="flex flex-wrap content-center mx-auto max-w-6xl">
                        <p className="text-lg text-gray-600">
                            U <strong>Frizerskom salonu „EN"</strong> posvećeni smo zdravlju i ljepoti vaše kose.
                            Koristimo vrhunske proizvode bez amonijaka, inovativne tehnike šišanja i bojanja te nudimo
                            specijalizirane tretmane poput <strong>THERMOCUT šišanja</strong>, <strong>balayage
                            pramenova</strong> i <strong>keratinskog ravnanja</strong>.
                        </p>
                        <p className="mt-2 text-lg text-gray-600">
                            Bilo da želite osvježiti frizuru, promijeniti boju ili pripremiti se za posebnu priliku – na
                            pravom ste mjestu!
                            Pogledajte našu ponudu i pronađite savršenu uslugu za sebe.
                        </p>
                    </div>
                </div>

                <div className="container mx-auto p-6">
                    <div className="p-3 rounded bg-red-200 text-red-800">
                        <p className="m-0">{error} Molimo pokušajte ponovno.</p>
                    </div>
                </div>
            </div>
        );
    }

    // Main render
    return (
        <div className="flex flex-wrap items-center">
            <PageHeader title="Usluge" />

            <div className="container mx-auto px-6 py-12">
                <div className="flex flex-wrap max-w-6xl mx-auto items-center text-center mb-8">
                    <p className="text-lg text-gray-600">
                        U <strong>Frizerskom salonu „EN"</strong> posvećeni smo zdravlju i ljepoti vaše kose.
                        Koristimo vrhunske proizvode bez amonijaka, inovativne tehnike šišanja i bojanja te nudimo
                        specijalizirane tretmane poput <strong>THERMOCUT šišanja</strong>, <strong>balayage
                        pramenova</strong> i <strong>keratinskog ravnanja</strong>.
                    </p>
                    <p className="mt-2 text-lg text-gray-600">
                        Bilo da želite osvježiti frizuru, promijeniti boju ili pripremiti se za posebnu priliku – na
                        pravom ste mjestu!
                        Pogledajte našu ponudu i pronađite savršenu uslugu za sebe.
                    </p>
                </div>

                <div className="flex flex-wrap">
                    <div className="w-full sm:w-6/12 sm:p-10">
                        {/* Accordion Component */}
                        <Accordion type="multiple" defaultValue={categories.map(cat => cat.id.toString())} className="w-full">
                            {categories.map((category) => (
                                <AccordionItem key={category.id} value={category.id.toString()} className="border-b">
                                    <AccordionTrigger className="text-2xl font-bold text-gray-800 underline decoration-gray-400 px-4 py-4 hover:bg-gray-100 transition rounded-md">
                                        {category.name}
                                    </AccordionTrigger>
                                    <AccordionContent className="p-4 bg-gray-50">
                                        <ul className="space-y-3">
                                            {category.services.map((service) => (
                                                <li key={service.id} className="flex justify-between items-center bg-white p-3 shadow-sm rounded-md">
                                                    <div>
                                                        <span className="block font-medium">{service.name}</span>
                                                        <span className="text-gray-500 text-sm">{service.duration} min</span>
                                                    </div>
                                                    <span className="text-gray-600 whitespace-nowrap">{service.price} €</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </AccordionContent>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>

                    <div className="w-full sm:w-6/12 sm:p-10">
                        {isMounted && processedImages.length > 0 && (
                            <GalleryMas images={processedImages} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesPage;