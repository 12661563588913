import React from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { MasonryPhotoAlbum } from 'react-photo-album'; // Using this for masonry grid
import "react-photo-album/masonry.css";

const GalleryMas  = ({ images }) => {

    const [index, setIndex] = React.useState(-1);

    return (
        <>
            <MasonryPhotoAlbum
                photos={images}
                direction="row"
                key={images.length} // Force re-render if photos change
                layout="masonry"
                onClick={({ index: current }) => setIndex(current)}
            />

            <Lightbox
                index={index}
                slides={images}
                open={index >= 0}
                close={() => setIndex(-1)}
            />
        </>
    );

};

export default GalleryMas;
