// src/pages/About.js
import React, { useState, useEffect } from "react";
import ServiceCards from "../components/ServiceCards";
import PageHeader from "../components/PageHeader";
import GalleryMas from "../components/GalleryMas";
import useSEO from "../hooks/useSEO";

const About = () => {

    const [isMounted, setIsMounted] = useState(false);

    useSEO(
        "O nama | Frizerski salon EN Rijeka - Stručna njega kose",
        "Frizerski salon EN u Rijeci pruža vrhunsku njegu kose od 1998. godine. Vlasnica Nataša Pavlić ima preko 30 godina iskustva u frizerskoj industriji, a salon nudi šišanje, bojanje, balayage i ThermoCut tretmane.",
        "https://frizerski-salon-en.hr/o-nama",
        {
            "@context": "https://schema.org",
            "@type": "HairSalon",
            "name": "Frizerski salon EN",
            "image": "https://frizerski-salon-en.hr/images/og-image.jpg",
            "url": "https://frizerski-salon-en.hr/o-nama",
            "telephone": "+385915948284",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Tihovac 12",
                "addressLocality": "Rijeka",
                "addressCountry": "HR"
            },
            "description": "Frizerski salon EN u Rijeci pruža vrhunsku njegu kose od 1998. godine. Vlasnica Nataša Pavlić ima preko 30 godina iskustva, sudjelovala je na mnogim natjecanjima i educirala se u Milanu.",
            "founder": {
                "@type": "Person",
                "name": "Nataša Pavlić",
                "description": "Vlasnica salona s više od 30 godina iskustva, sudjelovala na brojnim natjecanjima i usavršavala se u Milanu."
            },
            "openingHours": [
                "Mo 13:00-20:00",
                "Tu 08:00-14:00",
                "We 13:00-20:00",
                "Th 08:00-14:00",
                "Fr 13:00-20:00",
                "Sa 08:00-14:00"
            ],
            "priceRange": "€2.50 - €130.00",
            "sameAs": [
                "https://www.facebook.com/FrizerskiSalonEN",
                "https://www.instagram.com/FrizerskiSalonEN"
            ]
        }
    );

    useEffect(() => {
        // Delay mounting GalleryMas after About page fully renders
        const timer = setTimeout(() => {
            console.log("About page fully loaded, mounting GalleryMas...");
            setIsMounted(true);
            window.dispatchEvent(new Event("resize")); // Ensure Masonry recalculates
        }, 300); // Adjust delay if needed

        return () => clearTimeout(timer); // Cleanup timeout on unmount
    }, []);

    const importAll = (r) => r.keys().map(r);

    let images = importAll(require.context('/public/images/pages', false, /\.(jpg|jpeg|png)$/)).map((src, index) => {
        // Extracting file name (optional, for alt and other properties)

        let fileExtension = src.split('.').pop();
        let editedSrc = src.split('/').pop();
        //remove hash from file name, between two . characters
        let hashIndex = editedSrc.indexOf('.');
        let editedSrcWithoutHash = editedSrc.substring(0, hashIndex) + '.' + fileExtension;

        const alt = `Frizerski salon En ${index + 1}`;

        const img = new Image();
        img.src = `/images/pages/${editedSrcWithoutHash}`;

        return { src: `/images/pages/${editedSrcWithoutHash}`, alt, width:img.naturalWidth, height:img.naturalHeight };

    });
    images = images.filter(image => image.src.includes('about'));

    return (
        <div className="flex flex-wrap items-center">
            <PageHeader title="O nama" />

            <div className="container mx-auto px-6 py-12">

                <div className="flex flex-wrap items-center">

                    <div className="w-full sm:w-6/12 text-gray-700">
                        <p className="text-lg leading-relaxed mb-4">
                            <strong>Frizerski salon „EN“</strong> s ponosom brine o ljepoti i zdravlju kose od 1998. godine. Nalazimo se na adresi <strong>Tihovac 12, Rijeka</strong>, gdje s ljubavlju i stručnošću stvaramo frizure prilagođene vašim željama.
                        </p>

                        <p className="text-lg leading-relaxed mb-4">
                            Vlasnica salona, <strong>Nataša Pavlić</strong>, ima više od 30 godina iskustva u frizerskoj industriji. Sudjelovala je na brojnim natjecanjima, ostvarujući izvrsne rezultate, a kontinuirano se usavršava na seminarima u Hrvatskoj i inozemstvu. Najnovije edukacije pohađala je u Milanu, gdje je usvojila najnovije tehnike i trendove u svijetu frizura.
                        </p>

                        <p className="text-lg leading-relaxed mb-4">
                            U salonu posebnu pažnju posvećujemo zdravlju kose, koristeći visokokvalitetne proizvode koji ne sadrže amonijak. Ponosni smo što nudimo inovativne usluge poput bojanja kose 10-minutnim bojama te jedinstvenog <strong>THERMOCUT</strong> tretmana šišanja, pri kojem posebne škare zatvaraju vrhove, čineći kosu jačom, sjajnijom i otpornijom na pucanje.
                        </p>

                        <p className="text-lg leading-relaxed mb-4">
                            Također, ugrađujemo ekstenzije isključivo od prirodne kose, trajno ravnamo kovrčavu kosu te kreiramo postojane, prirodne kovrče koje s vremenom nestaju. Specijalizirani smo za sve tehnike pramenova, a posebno se ističemo brazilskom tehnikom balayagea.
                        </p>

                        <p className="text-lg leading-relaxed mb-4">
                            Kod nas možete plaćati kreditnim karticama, a po dogovoru dolazimo i na lokaciju vjenčanja.
                        </p>
                    </div>


                    <div className="w-full sm:w-6/12 sm:p-10">
                        {isMounted && <GalleryMas images={images}/>}
                    </div>
                </div>

            </div>



            {/* Cards Section */}
            <ServiceCards />
        </div>

    );
}

export default About;