// src/pages/About.js
import React from "react";
import PageHeader from "../components/PageHeader";
import { CheckCircle } from "lucide-react";
import useSEO from "../hooks/useSEO";

const Products = () => {

    useSEO(
        "Proizvodi | Frizerski salon EN - Rijeka",
        "U Frizerskom salonu EN koristimo Kaaral, talijanski brand vrhunskih proizvoda za njegu, bojenje i stiliziranje kose. Njihova inovativna formula osigurava zdravu, sjajnu i dugotrajno lijepu kosu.",
        "https://frizerski-salon-en.hr/proizvodi",
        {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Kaaral proizvodi za njegu i bojenje kose",
            "url": "https://frizerski-salon-en.hr/proizvodi",
            "description": "U Frizerskom salonu EN koristimo Kaaral proizvode za njegu, bojenje i stiliziranje kose. Kaaral je talijanski brand poznat po svojoj inovativnoj formuli koja osigurava zdravlje, sjaj i dugotrajnu ljepotu kose.",
            "brand": {
                "@type": "Brand",
                "name": "Kaaral"
            },
            "productCategory": "Hair Care, Hair Coloring, Hair Styling",
            "sameAs": [
                "https://www.facebook.com/FrizerskiSalonEN",
                "https://www.instagram.com/FrizerskiSalonEN"
            ]
        },
        "noindex, follow"
    );



    return (
        <div className="flex flex-wrap items-center">
            <PageHeader title="404 - Stranica nije pronađena" />

            <div className="max-w-6xl mx-auto px-6 py-12 text-center">
                <p className="text-lg text-gray-600 mb-8">
                    Ups! Stranica koju tražite nije pronađena. Molimo vas da provjerite URL i pokušajte ponovno.
                </p>

                <a href="/" className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-lg">
                    <CheckCircle size={24} className="inline-block mr-2" /> Povratak na početnu stranicu
                </a>
            </div>
        </div>

    );
}

export default Products;