import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);
    const closeMenu = () => setMenuOpen(false); // Close menu on link click

    return (
        <header className="fixed top-0 left-0 w-full bg-white/90 backdrop-blur-md shadow-md z-50">
            <div className="container mx-auto flex justify-between items-center py-4 px-6">
                {/* Logo */}
                <NavLink to="/" className="text-2xl font-extrabold text-secondary">
                    Frizerski salon <span className="text-primary">EN</span>
                </NavLink>

                {/* Mobile Menu Toggle */}
                <button onClick={toggleMenu} className="md:hidden">
                    <svg className="w-6 h-6 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                    </svg>
                </button>

                {/* Navigation Links (Desktop) */}
                <nav className="hidden md:flex space-x-6">
                    <NavLink to="/" className="hover:text-primary transition">Naslovna</NavLink>
                    <NavLink to="/usluge" className="hover:text-primary transition">Usluge</NavLink>
                    <NavLink to="/proizvodi" className="hover:text-primary transition">Proizvodi</NavLink>
                    <NavLink to="/o-nama" className="hover:text-primary transition">O nama</NavLink>
                    <NavLink to="/kontakt" className="hover:text-primary transition">Kontakt</NavLink>
                </nav>

                {/* Mobile Menu */}
                {menuOpen && (
                    <div className="absolute top-16 left-0 w-full bg-white shadow-md md:hidden">
                        <nav className="flex flex-col space-y-4 py-4 text-center">
                            <NavLink to="/" onClick={closeMenu} className="hover:text-primary transition">Naslovna</NavLink>
                            <NavLink to="/usluge" onClick={closeMenu} className="hover:text-primary transition">Usluge</NavLink>
                            <NavLink to="/proizvodi" onClick={closeMenu} className="hover:text-primary transition">Proizvodi</NavLink>
                            <NavLink to="/o-nama" onClick={closeMenu} className="hover:text-primary transition">O nama</NavLink>
                            <NavLink to="/kontakt" onClick={closeMenu} className="hover:text-primary transition">Kontakt</NavLink>
                        </nav>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Navbar;
