// src/pages/About.js
import React, { useState } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import PageHeader from "../components/PageHeader";
import useSEO from "../hooks/useSEO";

const Contact = () => {

    useSEO(
        "Kontakt | Frizerski salon EN - Rijeka",
        "Kontaktirajte Frizerski salon EN u Rijeci za rezervacije, upite ili dodatne informacije. Posjetite nas na adresi Tihovac 12 ili nas nazovite na +385915948284.",
        "https://frizerski-salon-en.hr/kontakt",
        {
            "@context": "https://schema.org",
            "@type": "ContactPage",
            "name": "Frizerski salon EN - Kontakt",
            "url": "https://frizerski-salon-en.hr/kontakt",
            "telephone": "+385915948284",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Tihovac 12",
                "addressLocality": "Rijeka",
                "addressCountry": "HR"
            },
            "description": "Za sve informacije i rezervacije, kontaktirajte Frizerski salon EN u Rijeci. Pozovite nas ili posjetite naš salon na adresi Tihovac 12.",
            "sameAs": [
                "https://www.facebook.com/FrizerskiSalonEN",
                "https://www.instagram.com/FrizerskiSalonEN"
            ]
        }
    );


    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        message: "",
    });

    const [statusMessage, setStatusMessage] = useState({ type: "", text: "" });
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Start loading

        try {
            // Check if grecaptcha is available
            if (!window.grecaptcha || !window.grecaptcha.ready) {
                console.error('reCAPTCHA not loaded');
                setStatusMessage({ type: "error", text: "reCAPTCHA nije učitan. Molimo osvježite stranicu." });
                setIsLoading(false);
                return;
            }

            // Execute reCAPTCHA v3
            window.grecaptcha.ready(async () => {
                try {
                    const token = await window.grecaptcha.execute('6Lc9ovoqAAAAALiiohNCsX4IjhzUJpJr3dBOX0X1', { action: 'contact' });

                    // Log the token to check if it's generating correctly
                    console.log('reCAPTCHA token:', token);

                    // Append the token to your form data
                    const formDataWithToken = {
                        ...formData,
                        'g-recaptcha-response': token
                    };

                    // Check if API_URL is defined
                    const API_URL = process.env.REACT_APP_API_URL;
                    if (!API_URL) {
                        console.error('API_URL is not defined');
                        setStatusMessage({ type: "error", text: "Greška u konfiguraciji. Molimo kontaktirajte administratora." });
                        setIsLoading(false);
                        return;
                    }

                    // Log the request before sending
                    console.log('Sending request to:', `${API_URL}/contact`);
                    console.log('Request body:', formDataWithToken);

                    const response = await fetch(`${API_URL}/contact`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(formDataWithToken),
                    });

                    // Log the response status
                    console.log('Response status:', response.status);

                    const data = await response.json();
                    console.log('Response data:', data);

                    if (response.ok) {
                        setStatusMessage({ type: "success", text: "Poruka je uspješno poslana!" });
                        setFormData({ name: "", phone: "", email: "", message: "" });
                    } else {
                        setStatusMessage({
                            type: "error",
                            text: `Greška pri slanju poruke: ${data.message || JSON.stringify(data.errors)}`
                        });
                    }
                } catch (error) {
                    console.error('Error in reCAPTCHA or form submission:', error);
                    setStatusMessage({
                        type: "error",
                        text: `Došlo je do greške pri reCAPTCHA ili slanju podataka: ${error.message}`
                    });
                } finally {
                    setIsLoading(false); // Stop loading
                }
            });
        } catch (error) {
            console.error('General error:', error);
            setStatusMessage({ type: "error", text: `Došlo je do greške. Pokušajte ponovo: ${error.message}` });
            setIsLoading(false); // Stop loading
        }
    };



    return (
        <div className={`flex flex-wrap items-center ${isLoading ? "cursor-wait" : ""}`}>
            <PageHeader title="Kontakt" />

            <div className="container mx-auto px-6 py-12">
                {/* Intro Section */}
                <div className="text-center mb-10">
                    <p className="text-lg text-gray-700">
                        Ako imate koje pitanje ili poruku slobodno nam se obratite na email adresu{" "}
                        <a href="mailto:natasa.ivanovic@yahoo.com" className="text-secondary font-bold hover:underline">
                            natasa.ivanovic@yahoo.com
                        </a>{" "}
                        ili pozivom na broj telefona{" "}
                        <a href="tel:+385915948284" className="text-secondary font-bold hover:underline">091 594 8284</a>.{" "}
                        Možete nas kontaktirati i ispunjavanjem donjeg obrasca. Pratite nas i preko Facebooka i Instagrama.
                    </p>
                </div>

                {/* Contact Form */}
                <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg">
                    <form onSubmit={handleSubmit} className={`max-w-lg mx-auto bg-white p-6 rounded-lg`}>
                    {/* Display success or error message */}
                        {statusMessage.text && (
                            <div className={`mb-4 p-3 rounded ${statusMessage.type === "success" ? "bg-green-200 text-green-800" : "bg-red-200 text-red-800"}`}>
                                {statusMessage.text}
                            </div>
                        )}

                        <div className="mb-4">
                            <label className="block text-gray-700">Ime i prezime:</label>
                            <input
                                type="text"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                className="w-full px-4 py-2 border rounded-lg"
                                disabled={isLoading}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700">Broj telefona: *</label>
                            <input
                                type="tel"
                                required
                                value={formData.phone}
                                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                className="w-full px-4 py-2 border rounded-lg"
                                disabled={isLoading}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700">Email adresa: *</label>
                            <input
                                type="email"
                                required
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                className="w-full px-4 py-2 border rounded-lg"
                                disabled={isLoading}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700">Poruka: *</label>
                            <textarea
                                required
                                value={formData.message}
                                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                                className="w-full px-4 py-2 border rounded-lg"
                                disabled={isLoading}
                            ></textarea>
                        </div>

                        <button
                            type="submit"
                            className={`w-full py-2 rounded-lg text-white transition transform ${isLoading ? "bg-gray-500 cursor-wait" : "bg-primary hover:bg-secondary"}`}
                            disabled={isLoading}
                        >
                            {isLoading ? "Slanje..." : "Pošalji poruku"}
                        </button>
                    </form>
                </div>

                {/* Social Media Links */}
                <div className="text-center mt-10">
                    <h2 className="text-2xl font-semibold text-gray-800">Pratite nas na društvenim mrežama</h2>
                    <div className="flex justify-center gap-6 mt-4">
                        <a href="https://www.facebook.com/FrizerskiSalonen" target="_blank" rel="noopener noreferrer" className="text-3xl text-blue-600 hover:text-blue-800 transition">
                            <FaFacebook />
                        </a>
                        <a href="https://www.instagram.com/frizerskisalonen" target="_blank" rel="noopener noreferrer" className="text-3xl text-pink-500 hover:text-pink-700 transition">
                            <FaInstagram />
                        </a>
                    </div>
                </div>

            </div>

            {/* Google Maps Section */}
            <div className="mt-12 w-full h-[400px] lg:h-[600px]">
                <iframe
                    title="Google Maps"
                    className="w-full h-full border-0 shadow-md"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2870.846689442056!2d14.44217671547236!3d45.32700157909969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4764a1f61c9b8741%3A0x8c2f614c0b0b4f1b!2sTihovac%2012%2C%2051000%2C%20Rijeka%2C%20Croatia!5e0!3m2!1sen!2shr!4v1614959389020!5m2!1sen!2shr"
                    allowFullScreen
                    loading="lazy"
                ></iframe>
            </div>
        </div>





    );
}

export default Contact;