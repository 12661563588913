import React from "react";
import PageHeader from "../components/PageHeader";
import { CheckCircle } from "lucide-react";
import useSEO from "../hooks/useSEO";

const Products = () => {

    useSEO(
        "Proizvodi | Frizerski salon EN - Rijeka",
        "U Frizerskom salonu EN koristimo Kaaral, talijanski brand vrhunskih proizvoda za njegu, bojenje i stiliziranje kose. Njihova inovativna formula osigurava zdravu, sjajnu i dugotrajno lijepu kosu.",
        "https://frizerski-salon-en.hr/proizvodi",
        {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Kaaral proizvodi za njegu i bojenje kose",
            "url": "https://frizerski-salon-en.hr/proizvodi",
            "description": "U Frizerskom salonu EN koristimo Kaaral proizvode za njegu, bojenje i stiliziranje kose. Kaaral je talijanski brand poznat po svojoj inovativnoj formuli koja osigurava zdravlje, sjaj i dugotrajnu ljepotu kose.",
            "brand": {
                "@type": "Brand",
                "name": "Kaaral"
            },
            "productCategory": "Hair Care, Hair Coloring, Hair Styling",
            "sameAs": [
                "https://www.facebook.com/FrizerskiSalonEN",
                "https://www.instagram.com/FrizerskiSalonEN"
            ]
        }
    );


    const benefits = [
        {
            title: "Profesionalni proizvodi visoke kvalitete",
            description: "Koristimo Kaaral proizvode u našem salonu kako bismo osigurali vrhunsku njegu vaše kose – od bojenja do tretmana i stiliziranja.",
        },
        {
            title: "Made in Italy – Spoj stila i inovacije",
            description: "Kaaral proizvodi spajaju kvalitetu i najnovije trendove, pružajući frizerima i klijentima inspiraciju za savršene frizure.",
        },
        {
            title: "Sigurnost i njega za zdravu kosu",
            description: "Bez amonijaka i štetnih sastojaka – naši proizvodi su nježni prema kosi, a istovremeno osiguravaju dugotrajan i postojan rezultat.",
        },
        {
            title: "Obitelj Kaaral – Strast prema kosi",
            description: "Dugogodišnja tradicija i predanost ljepoti kose čine Kaaral pouzdanim partnerom u našem salonu.",
        },
    ];

    return (
        <div className="flex flex-wrap items-center">
            <PageHeader title="Proizvodi" />

            <div className="max-w-6xl mx-auto px-6 py-12 text-center">
                <p className="text-lg text-gray-600 mb-8">
                    U našem salonu koristimo <strong><a href="https://kaaral.com.hr/italija/" className="underline" target="_blank" rel="noopener noreferrer">Kaaral</a></strong> – talijanski brand vrhunskih proizvoda
                    za njegu, bojenje i stiliziranje kose. Njihova inovativna formula osigurava zdravu,
                    sjajnu i dugotrajno lijepu kosu.
                </p>

                {/* Baco Color Fast Highlight */}

                <div className="mt-12 bg-primaryLight p-6 rounded-lg shadow-md text-secondary mb-10">
                    <h2 className="text-2xl font-bold text-primary">Istaknuti Proizvod: Baco Color Fast</h2>
                    <p className="mt-4 text-lg">
                        Baco Color Fast je inovativna boja za kosu koja prekriva sijede u samo <strong>10 minuta</strong>. S obogaćenom formulom koja sadrži <strong>K-VEG Keratin</strong>, aminokiseline i <strong>Marula ulje</strong>, pruža zaštitu kosi uz visoki sjaj i dugotrajnu postojanost boje.
                    </p>
                    <p className="mt-2 text-lg">
                        U našem salonu, koristimo Baco Color Fast kako bismo osigurali brzu, učinkovitu i kvalitetnu uslugu bojenja kose, idealnu za klijente koji žele besprijekoran rezultat u kraćem vremenu.
                    </p>
                </div>


                <img src="/images/kaaral-logo.png" alt="Kaaral Logo" className="mx-auto w-40 mb-12" />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-left">
                    {benefits.map((item, index) => (
                        <div key={index} className="bg-gray-100 p-4 rounded-lg shadow-sm flex items-start gap-4">
                            <CheckCircle className="text-green-600 w-6 h-6 flex-shrink-0" />
                            <div>
                                <h3 className="text-xl font-semibold mb-3">{item.title}</h3>
                                <p className="text-gray-700 text-left mb-2">{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

}

export default Products;