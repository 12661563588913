import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-gray-300 py-8">
            <div className="container mx-auto px-6 md:flex md:justify-between">
                {/* Left Section: Business Info */}
                <div className="mb-6 md:mb-0">
                    <h2 className="text-lg font-semibold text-white">Radno vrijeme</h2>
                    <ul className="mt-2 text-sm grid grid-cols-2 gap-y-1">
                        <li className="text-gray-300">Ponedjeljak:</li>
                        <li className="text-primary">13:00 - 20:00</li>

                        <li className="text-gray-300">Utorak:</li>
                        <li className="text-primary">08:00 - 14:00</li>

                        <li className="text-gray-300">Srijeda:</li>
                        <li className="text-primary">13:00 - 20:00</li>

                        <li className="text-gray-300">Četvrtak:</li>
                        <li className="text-primary">08:00 - 14:00</li>

                        <li className="text-gray-300">Petak:</li>
                        <li className="text-primary">13:00 - 20:00</li>

                        <li className="text-gray-300">Subota:</li>
                        <li className="text-primary">08:00 - 14:00</li>

                        <li className="text-gray-300">Nedjelja:</li>
                        <li className="text-red-500">Zatvoreno</li>
                    </ul>
                </div>


                {/* Middle Left Section: Navigation Links */}
                <nav className="mb-6 md:mb-0">
                    <h2 className="text-lg font-semibold text-white">Navigacija</h2>
                    <ul className="mt-2 space-y-2 text-sm">
                        <li><NavLink to="/" className="hover:text-primary">Naslovna</NavLink></li>
                        <li><NavLink to="/usluge" className="hover:text-primary">Usluge</NavLink></li>
                        <li><NavLink to="/proizvodi" className="hover:text-primary">Proizvodi</NavLink></li>
                        <li><NavLink to="/o-nama" className="hover:text-primary">O nama</NavLink></li>
                        <li><NavLink to="/kontakt" className="hover:text-primary">Kontakt</NavLink></li>
                    </ul>
                </nav>

                {/* Middle Right Section: Contact Info */}
                <div className="mb-6 md:mb-0">
                    <h2 className="text-lg font-semibold text-white">Kontakt</h2>
                    <ul className="mt-2 text-sm">
                        <li>📞 <a href="tel:+385915948284" className="hover:text-primary">091 594 8284</a></li>
                        <li>📧 <a href="mailto:frizerskisalonen@gmail.com" className="hover:text-primary">frizerskisalonen@gmail.com</a></li>
                    </ul>
                </div>


                {/* Right Section: Contact Info */}
                <div>
                    <h2 className="text-lg font-semibold text-white">Društvene mreže</h2>
                    <ul className="mt-2 text-sm grid grid-cols-4 gap-y-1">
                        <li>
                            <a href="https://www.facebook.com/FrizerskiSalonen" target="_blank" rel="noopener noreferrer">
                                <img src="/images/facebook.svg" alt="Facebook" className="w-6 h-6 hover:opacity-75 " />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/frizerskisalonen/" target="_blank" rel="noopener noreferrer">
                                <img src="/images/instagram.svg" alt="Instagram" className="w-6 h-6 hover:opacity-75" />
                            </a>
                        </li>
                    </ul>
                </div>

            </div>



            {/* Bottom Section */}
            <div className="text-center text-xs text-gray-500 mt-6">
                &copy; {new Date().getFullYear()} Frizerski salon EN. Sva prava pridržana.
            </div>
        </footer>
    );
}

export default Footer;
