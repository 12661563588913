import { useEffect } from "react";

const useSEO = (
    title,
    description,
    url = window.location.href,
    jsonLD = null,
    robots = "index, follow"
) => {
    useEffect(() => {
        document.title = title;

        // Helper function to update/create meta tags
        const setMetaTag = (name, content) => {
            const selector = name.startsWith("og:") || name.startsWith("twitter:")
                ? `meta[property="${name}"]`
                : `meta[name="${name}"]`;

            let tag = document.querySelector(selector);

            if (!tag) {
                tag = document.createElement("meta");
                tag.setAttribute(
                    name.startsWith("og:") || name.startsWith("twitter:")
                        ? "property"
                        : "name",
                    name
                );
                document.head.appendChild(tag);
            }
            tag.setAttribute("content", content);
        };

        // Standard meta tags
        setMetaTag("description", description);
        setMetaTag("author", "Frizerski salon EN");
        setMetaTag("robots", robots);

        // Open Graph tags
        setMetaTag("og:title", title);
        setMetaTag("og:description", description);
        setMetaTag("og:type", "website");
        setMetaTag("og:url", url);

        // Twitter tags
        setMetaTag("twitter:title", title);
        setMetaTag("twitter:description", description);

        // Canonical URL
        let canonicalLink = document.querySelector("link[rel='canonical']");
        if (!canonicalLink) {
            canonicalLink = document.createElement("link");
            canonicalLink.setAttribute("rel", "canonical");
            document.head.appendChild(canonicalLink);
        }
        canonicalLink.setAttribute("href", url);

        // Structured data
        const existingJSONLD = document.querySelector('script[type="application/ld+json"]');
        if (existingJSONLD) existingJSONLD.remove();

        if (jsonLD) {
            const script = document.createElement("script");
            script.type = "application/ld+json";
            script.textContent = JSON.stringify(jsonLD);
            document.head.appendChild(script);
        }

    }, [title, description, url, jsonLD, robots]);
};

export default useSEO;