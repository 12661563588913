// src/pages/Home.js
import React from 'react';
import { NavLink } from "react-router-dom";
import ServiceCards from "../components/ServiceCards";
import useSEO from "../hooks/useSEO";

const Home = () => {

    useSEO(
        "Frizerski salon EN | Rijeka - Stručna njega kose",
        "Frizerski salon EN u Rijeci brine o ljepoti i zdravlju vaše kose od 1998. godine. Nudimo šišanje, bojanje, feniranje i specijalizirane tretmane za kosu.",
        "https://frizerski-salon-en.hr",
        {
            "@context": "https://schema.org",
            "@type": "HairSalon",
            "name": "Frizerski salon EN",
            "image": "https://frizerski-salon-en.hr/images/og-image.jpg",
            "url": "https://frizerski-salon-en.hr",
            "telephone": "+385915948284",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Tihovac 12",
                "addressLocality": "Rijeka",
                "addressCountry": "HR"
            },
            "description": "Frizerski salon EN u Rijeci pruža profesionalne frizerske usluge poput šišanja, bojanja, feniranja i tretmana njege kose. Koristimo visokokvalitetne proizvode bez amonijaka.",
            "openingHours": [
                "Mo 13:00-20:00",
                "Tu 08:00-14:00",
                "We 13:00-20:00",
                "Th 08:00-14:00",
                "Fr 13:00-20:00",
                "Sa 08:00-14:00"
            ],
            "priceRange": "€2.50 - €130.00",
            "sameAs": [
                "https://www.facebook.com/FrizerskiSalonEN",
                "https://www.instagram.com/FrizerskiSalonEN"
            ]
        }
    );

    return (
        <div className="flex flex-wrap items-center">
            {/* Left Side - Text Section */}

            <div className="w-full sm:w-8/12 mb-10">
                {/*<div className="w-full sm:w-8/12 mb-10 bg-gradient-to-r from-primary to-white-500">*/}

                {/* Abstract Shapes in the Background */}
                <div className="absolute top-0 left-0 w-full h-full bg-abstract z-0"></div>

                    <div className="container mx-auto h-full sm:p-10 xl:px-40  relative overflow-hidden">

                    <header className="container py-10 sm:py-0 px-4 lg:flex mt-10 items-center h-full lg:mt-0 relative z-10">
                        <div className="w-full">
                            {/* Title */}
                            <h1 className="text-4xl lg:text-6xl font-extrabold text-gray-900 text-left">
                                Frizerski salon <span className="text-primary">EN</span>
                            </h1>
                            <div className="w-20 h-2 bg-primary my-4"></div>

                            {/* Description */}
                            <p className="text-lg text-gray-700 leading-relaxed max-w-2xl mb-8 text-left">
                                Frizerski salon „EN“ s ponosom brine o ljepoti i zdravlju kose od 1998. godine.
                                Nalazimo se na adresi Tihovac 12 u Rijeci, gdje s ljubavlju i stručnošću stvaramo frizure prilagođene vašim željama.
                            </p>

                            {/* Call to Action */}
                            <NavLink to="/o-nama" className="bg-primary hover:bg-secondary text-white text-lg font-semibold px-6 py-3 rounded-lg shadow-md transition transform hover:scale-105 hover:bg-primary-dark">
                                Saznaj više
                            </NavLink>
                        </div>
                    </header>

                </div>

            </div>

            {/* Right Side - Image */}
            <div className="w-full sm:w-4/12">
                <img
                    src="/images/hero.jpg"
                    alt="Hairdresser"
                    className="w-full h-64 sm:h-screen object-cover shadow-lg"
                />
            </div>

            {/* Cards Section */}
            <ServiceCards />
        </div>

    );
}

export default Home;
